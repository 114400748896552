import { ChevronRight } from "@mui/icons-material";
import { Alert, Paper, Snackbar, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
  AboutBannerHead,
  AboutBannerHead1,
  AboutParaTypo,
  BlueContainerSection,
  BlueContainerSection1,
  ContentDiv,
  FilledTextField, TransparentHead, TransparentPara
} from "../styledcomponents/beforeLoginWidgets";
import { StyledGreenButton } from "../styledcomponents/styledDBwidgets";
import axios from "axios";
import constantValues from "../Common/ConstantValues";
import { contactAuthApi } from "../../http/allApis";
import {offerContent} from "../Common/beforeLoginPageContents";

function ContactPage() {
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    message: ""
  });
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertstatus, setAlertstatus] = useState("");

  const onChangeValues = (e, val) => {
    if (val === "name") {
      setContactDetails((prev) => ({
        ...prev,
        name: e.target.value.toUpperCase()
      }));
    } else if (val === "email") {
      setContactDetails((prev) => ({ ...prev, email: e.target.value }));
    } else if (val === "message") {
      setContactDetails((prev) => ({ ...prev, message: e.target.value }));
    }
  };

  const handleContactApi = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var string = /^[a-z\s]+$/i;
    if (!string.test(contactDetails.name)) {
      setMessage("Name Field Allow Alphabet only!");
      setAlertstatus("warning");
      setOpen(true);
    } else if (!emailPattern.test(contactDetails.email)) {
      setMessage("Please Enter Vaild Mail Id");
      setAlertstatus("warning");
      setOpen(true);
    } else if (contactDetails.message.length === 0) {
      setMessage("Please Enter Comment Message!");
      setAlertstatus("warning");
      setOpen(true);
    } else {
      let payload = {
        email: contactDetails.email,
        fullname: contactDetails.name,
        message: contactDetails.message
      };
      contactAuthApi(payload)
        .then(function (response) {
          if (response.data.result === true) {
            setMessage("Form Submitted Successfully!");
            setContactDetails({
              name: "",
              email: "",
              message: ""
            });
            setAlertstatus("success");
          } else {
            setMessage("Error");
            setAlertstatus("warning");
          }
          setOpen(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div style={{marginTop: "2rem"}}>
      <AboutParaTypo variant="p">
        If you have any queries, comments or suggestions on how we may
        improve, please contact us
      </AboutParaTypo>
      <ContentDiv style={{ backgroundColor: theme.palette.background.default }}>
        <div
          style={{
            margin: "2rem 0",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: "2rem 1rem",
              backgroundColor: theme.palette.background.paper,
              width: "35rem"
            }}
          >
            <FilledTextField
              label="Your Name"
              autoFocus
              type="text"
              value={contactDetails.name}
              id="name"
              variant="filled"
              style={{ marginTop: "1rem" }}
              fullWidth
              onChange={(e) => onChangeValues(e, "name")}
            />
            <FilledTextField
              label="Email address"
              type={"email"}
              value={contactDetails.email}
              id="email"
              variant="filled"
              style={{ marginTop: "1rem" }}
              fullWidth
              onChange={(e) => onChangeValues(e, "email")}
            />
            <FilledTextField
              label="Message"
              value={contactDetails.message}
              id="message"
              variant="filled"
              style={{ marginTop: "1rem" }}
              fullWidth
              multiline
              onChange={(e) => onChangeValues(e, "message")}
              rows={4}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "1rem"
              }}
            >
              <StyledGreenButton
                disabled={
                  contactDetails.email === "" ||
                  contactDetails.name === "" ||
                  contactDetails.message === ""
                }
                onClick={handleContactApi}
                style={{ width: "6rem" }}
              >
                Submit <ChevronRight />
              </StyledGreenButton>
            </div>
          </Paper>
        </div>
      </ContentDiv>
      <div>
        <AboutParaTypo variant="p">
          Alternatively you can e-mail us at{" "}
          <a
              href="mailto:support@pgneet.net"
              // target="_blank"
              style={{ color: "#31a188", textDecoration: "none" }}
          >
            {" "}
            support@pgneet.net
          </a>
        </AboutParaTypo>
        <AboutParaTypo variant="p">
          <TransparentPara marginTop={"1rem"} fontWeight={"bold"}>
            Registered Office:
          </TransparentPara>
          <p>PSR Medical Services<br></br>
            F2 AG Gold Apartments<br></br>
            14 Ramanujam Street, Thiruvalluvar Nagar,<br></br>
            Pammal Chennai 600075<br></br>
            TAMIL NADU. INDIA<br></br>
            <a
                href="mailto:psrmsl@outlook.com"
                // target="_blank"
                style={{ color: "#31a188", textDecoration: "none" }}
            >
              {" "}
              psrmsl@outlook.com<br></br>
            </a>{" "}
            +91-99402 50516
          </p>
        </AboutParaTypo>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ContactPage;
